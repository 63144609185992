<template>
    <div class="write">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>交易筛选</span>
                    <el-input
                        size="small"
                        style="width: 200px"
                        placeholder="交易公司、交易金额"
                        v-model="fieldVal"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="
                            () => {
                                currentPage = 1;
                                pageSize = 20;
                                getTableData();
                                getStatistics();
                            }
                        "
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                    <span>入账公司</span>
                    <el-select
                        v-model="typeB"
                        size="small"
                        style="width: 200px; margin-left: 10px"
                        placeholder="请选择入账公司"
                        clearable
                        @change="
                            () => {
                                currentPage = 1;
                                pageSize = 20;
                                getTableData();
                                getStatistics();
                            }
                        "
                    >
                        <el-option
                            v-for="item in $tableDataHandle.selectTypeB()"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                    <span>时间</span>
                    <el-date-picker
                        v-model="createDate"
                        type="month"
                        size="small"
                        value-format="yyyy-MM"
                        placeholder="请选择年月"
                        @change="
                            () => {
                                currentPage = 1;
                                pageSize = 20;
                                getTableData();
                                getStatistics();
                            }
                        "
                    >
                    </el-date-picker>
                </p>
            </div>
        </div>
        <div class="table-title">
            <p>
                总金额￥{{ statistics.totalArriveAmount }} ({{
                    statistics.arriveCount
                }}
                笔)
            </p>
            <span>
                <button class="add" @click="addWrite">
                    <i class="el-icon-plus"></i> 新建到款信息
                </button></span
            >
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <el-table-column label="交易日" prop="arriveTime" width="160">
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="交易金额"
                prop="arriveAmount"
                show-overflow-tooltip
                width="80"
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="交易公司"
                prop="contractA"
                show-overflow-tooltip
                width="193"
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="入账公司" width="193" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span class="text" v-if="scope.row.typeB != 9">
                        {{ $tableDataHandle.typeB(scope.row.typeB) }}
                    </span>
                    <span class="text" v-if="scope.row.typeB == 9">{{
                        scope.row.contractB
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="入账方式" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span class="text">
                        {{
                            scope.row.entryMethod == 1
                                ? '对公'
                                : scope.row.entryMethod == 2
                                ? '微信'
                                : scope.row.entryMethod == 3
                                ? '支付宝'
                                : '- -'
                        }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column show-overflow-tooltip width="90">
                <template slot="header">
                    <el-dropdown trigger="click">
                        <span class="el-dropdown-link">
                            核销状态<i
                                class="el-icon-arrow-down el-icon--right"
                            ></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-checkbox-group
                                v-model="checkList"
                                style="padding-left: 25px"
                            >
                                <el-checkbox
                                    label="待核销"
                                    style="margin-bottom: 10px"
                                ></el-checkbox>
                                <br />
                                <el-checkbox
                                    style="margin-bottom: 10px"
                                    label="已核销"
                                ></el-checkbox
                                ><br />
                                <el-checkbox label="作废"></el-checkbox><br />
                                <el-dropdown-item style="margin-left: -20px">
                                    <el-button type="text" @click="onScreen"
                                        >确定</el-button
                                    >
                                    <el-button type="text" @click="onReset"
                                        >重置</el-button
                                    >
                                </el-dropdown-item>
                            </el-checkbox-group>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.status == 1
                            ? '未核销'
                            : scope.row.status == 2
                            ? '已核销'
                            : scope.row.status == 3
                            ? '作废'
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="操作"
                fixed="right"
                width="120"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        v-if="scope.row.status == 1 && btnP.allmoneyVoid"
                        style="color: #2370eb"
                        @click="onVoid(scope.row)"
                        >作废</el-button
                    >
                    <el-button
                        type="text"
                        v-if="scope.row.status == 1 && btnP.allmoneyUpdate"
                        style="color: #2370eb"
                        @click="onEdit(scope.row)"
                        >编辑</el-button
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 新增 -->
        <el-dialog
            :visible.sync="drawerAddWrite"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新建到款信息
                </div>
            </template>
            <AddWrite @close="handleClose" />
        </el-dialog>
        <!-- 编辑 -->
        <el-dialog
            :visible.sync="drawerEditWrite"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    编辑到款信息
                </div>
            </template>
            <EditWrite @close="handleClose" ref="edit" />
        </el-dialog>
        <!-- 作废 -->
        <el-dialog
            :visible.sync="drawerVoid"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    作废到款
                </div>
            </template>
            <VoidWrite @close="handleClose" ref="void" />
        </el-dialog>
    </div>
</template>
<script>
import { arriveList, statisticsList } from '@/api/user/finance/write';
import AddWrite from '../write/addWrite.vue';
import EditWrite from '../write/editWrite.vue';
import VoidWrite from '../write/voidWrite.vue';
export default {
    components: {
        AddWrite,
        EditWrite,
        VoidWrite,
    },
    data() {
        return {
            statistics: {},
            field: '',
            fieldVal: '',
            createTime: '',
            createDate: '',
            tableData: [],
            typeB: '',
            pagesize: 20,
            total: 0,
            currentPage: 1,
            checkList: [],
            drawerAddWrite: false,
            drawerEditWrite: false,
            drawerVoid: false,
            btnP: {},
        };
    },
    mounted() {
        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            // console.log(btn);

            this.getTableData();
            this.getStatistics();
        },
        getStatistics() {
            let data = {
                param: {},
            };
            data.param.arriveStatusList = [1, 2, 3];
            if (this.checkList.length > 0) {
                data.param.arriveStatusList = [];
                for (let i in this.checkList) {
                    if (this.checkList[i] == '待核销') {
                        data.param.arriveStatusList.push(1);
                    } else if (this.checkList[i] == '已核销') {
                        data.param.arriveStatusList.push(2);
                    } else if (this.checkList[i] == '作废') {
                        data.param.arriveStatusList.push(3);
                    }
                }
            }
            statisticsList(data).then((res) => {
                this.statistics = res.data;
            });
        },
        getTableData() {
            let data = {
                param: {},
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (this.fieldVal) {
                data.param.name = this.fieldVal;
            }
            if (this.createDate) {
                data.param.dateParam = this.createDate;
            }
            if (this.typeB) {
                data.param.typeB = this.typeB;
            }
            arriveList(data).then((res) => {
                this.total = res.data.total;
                this.tableData = res.data.list;
            });
        },
        // 创建时间点击
        createitem(e) {
            e === this.createTime
                ? (this.createTime = '')
                : (this.createTime = e);

            this.getTableData();
        },

        // 时间输入框变化
        expireTime() {
            if (this.createDate && this.createDate.length > 0) {
                this.createTime = '';
            }
            this.getTableData();
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getTableData();
        },
        // 状态查询
        onScreen() {
            this.getTableData();
            this.getStatistics();
        },
        onReset() {
            this.checkList = [];
            this.getTableData();
            this.getStatistics();
        },
        // 新建
        addWrite() {
            this.drawerAddWrite = true;
        },
        // 编辑
        onEdit(row) {
            this.drawerEditWrite = true;
            let that = this;
            setTimeout(() => {
                that.$refs.edit.getData(row);
            }, 50);
        },
        // 作废
        onVoid(row) {
            this.drawerVoid = true;
            let that = this;
            setTimeout(() => {
                that.$refs.void.getData(row);
            }, 50);
        },
        handleClose() {
            this.drawerAddWrite = false;
            this.drawerVoid = false;
            this.drawerEditWrite = false;
            this.getTableData();
            this.getStatistics();
        },
        searchEmpty() {
            this.field = '';
            this.fieldVal = '';
            this.createTime = '';
            this.createDate = [];
            this.getTableData();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.write {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        .add {
            border-color: #d9d9d9;
            color: #333333;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
        }

        button:hover {
            background: #2370eb;
            color: #ffffff;
            border-color: #2370eb;
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
<style>
.el-dropdown-menu__item:hover {
    background: none !important;
}
</style>
